<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มรายการยอดยกมา
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row mb-3 d-flex justify-content-center">
          <label for="input1" class="col-sm-10">ค้นหาสินค้า</label>
          <div class="col-sm-5">
            <input
              v-model="searchProduct"
              type="text"
              class="form-control"
              id="input1"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>

          <div class="col-sm-3">
            <div
              class="
                form-check form-check-inline form-check-custom form-check-solid
              "
            >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="input1"
                id="flexRadioChecked"
                checked="checked"
              />
              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >Item no.</label
              >
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="input2"
                id="flexRadioChecked2"
              />
              <label
                class="form-check-label"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >Barcode</label
              >
            </div>
          </div>

          <div class="col-sm-12 col-md-2">
            <button
              type="button"
              class="btn btn-sm btn-light-primary"
              style="font-size: 14px; font-weight: bold; width: 100%"
              width="100%"
              @click="cancel"
            >
              ค้นหา
            </button>
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Item no.</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.itemNo"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Item detail</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.itemDetail"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Barcode</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.barcode"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <!-- -->

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >งวดบัญชีที่</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.accountingPeriod"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>
        <div class="row mb-4 d-flex justify-content-center">
          <label for="docNumber" class="required form-label col-sm-10"
            >เลขที่เอกสาร</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.docNumber"
              type="text"
              class="form-control"
              name="docNumber"
              id="docNumber"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <label for="amount" class="required form-label col-sm-10"
            >จำนวน(หน่วย)</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.amount"
              type="number"
              class="form-control"
              name="amount"
              id="amount"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row mb-4 d-flex justify-content-center">
          <label for="pricePerUnit" class="required form-label col-sm-10"
            >ราคา / หน่วย(บาท)</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.pricePerUnit"
              type="number"
              class="form-control"
              id="pricePerUnit"
              name="pricePerUnit"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row mb-4 d-flex justify-content-center">
          <label for="discountPerUnit" class="form-label col-sm-10"
            >ส่วนลด / หน่วย(บาท)</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.discountPerUnit"
              type="number"
              class="form-control"
              id="discountPerUnit"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >VAT</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.vat"
              id="validationTooltip01"
            >
              <option value="1">ไม่มี</option>
              <option value="2">0 %</option>
              <option value="3">7 %</option>
            </select>
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="receiveDate" class="required form-label"
              >วันที่รับสินค้า</label
            >
            <input
              v-model="form.receiveDate"
              type="date"
              class="form-control"
              id="receiveDate"
              name="receiveDate"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5" id="margin-text">
            <label for="consignee" class="required form-label"
              >ผู้รับสินค้า</label
            >
            <input
              v-model="form.consignee"
              type="text"
              class="form-control"
              id="consignee"
              name="consignee"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <label for="validationTooltip01" class="required form-label col-sm-10"
            >เข้าคลัง</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.warehouseTo"
              id="validationTooltip01"
            >
              <option value="1">One</option>
              <option value="2">Two</option>
            </select>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit"
          >
            บันทึก
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            ยกเลิก
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    form: {
      itemNo: "",
      itemDetail: "",
      barcode: "",
      accountingPeriod: "",
      docNumber: "",
      amount: "",
      pricePerUnit: "",
      discountPerUnit: "",
      vat: "",
      receiveDate: "",
      consignee: "",
      warehouseTo: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),
  methods: {
    async submit() {
      await this.validateForm();
      this.isSubmit = true;
      Swal.fire({
        icon: "success",
        title: "เพิ่มสำเร็จ",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      }).then(() => {
        this.$router.go(-1);
      });
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
